<template>
  <div class="404-root">
    <FixedNavbar />
    <section class="page404">
      <img class="img-fluid" src="../assets/images/404-image.jpg" alt="404" />
      <h3>Oops! This is awkward</h3>
      <p>
        You're looking for a page that doesn't exist. Head back to
        <a
          href="/"
          target="_self"
        >Home Page</a>
      </p>
    </section>
    <Footer />
  </div>
</template>

<script>
import FixedNavbar from "@/components/Common/Navbars/FixedNavbar.vue";
import Footer from "@/components/Common/Footer.vue";

export default {
  components: {
    FixedNavbar,
    Footer
  }
};
</script>